/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme
} from '@mui/material/styles';

import App from './containers/App';
import RootStore from './models/RootStore';

const theme = createTheme({
  typography: {
    fontFamily: `'Open Sans', sans-serif`
  },
  palette: {
    primary: {
      main: '#007fa3'
    },
    secondary: {
      main: '#f50057'
    },
    error: {
      main: '#a9353d'
    }
  }
});

const root = createRoot(document.getElementById('app'));

root.render(
  <Provider store={RootStore}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);
